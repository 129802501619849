<div class="container">
    <div class="content">
        <div class="title-container">
            <img src="../../../assets/DFDS-logo.png" alt="Travelex UK" />

            <div class="title-text-container">
                <span class="title-text">European Ferry Travel</span>
            </div>
        </div>
        <div class="top-banner">
            <img src="../../../assets/Pearl-02-Hero-2022.webp" alt="Travelex UK" />
            <div class="top-banner-curved-edge"></div>
        </div>

        <div class="page-content">
            <div class="message-container">
                <span class="message-title-text">Payment Cancelled</span>
            </div>

            <div class="success-text-container">
                <p class="personal-field-title">Your payment was cancelled.</p>
                <p class="personal-field-title">Click on the button below to place a new order.</p>
            </div>
    
            <div class="btn-container">
                <button class="btn-style" mat-raised-button color="custom-color" (click)="navigateToHome()">
                    New Order
                </button>
            </div>
        </div>
    </div>
</div>