import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MaterialModule } from '../../modules/material/material.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-cancel-page',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './cancel-page.component.html',
  styleUrl: './cancel-page.component.scss'
})
export class CancelPageComponent {

  constructor(private router: Router) {}

  navigateToHome()
  {
    this.router.navigateByUrl('home');
  }

}
